/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type {
    AppWorkoutWithRelations as AppWorkout,
    AppWorkoutCreatePayload,
} from "@volley/data/dist/types/app-workout";
import type { ServePosition } from "@volley/shared/apps/app-common-models";
import {
    ServeChallengeAppConfig,
    ServeChallengeConfig,
    ServeChallengeParameters,
} from "@volley/shared/apps/servechallenge-models";
import type { JSONObject } from "@volley/shared/common-models";

import { logFetchError } from "../../../../../util/fetchApi";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { useCurrentUser } from "../../../../hooks/currentUser";
import { useStatus } from "../../../../hooks/status";
import { useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import OptionSelector from "../../Shared/OptionSelector";
import PlayAppBar from "../../Shared/PlayAppBar";
import ThrowCount from "../../Shared/ThrowCount";
import useAppWorkouts from "../../db";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import LocalizingDialog from "../serve-and-volley/LocalizingDialog";

import Court from "./Court";
import Scoreboard from "./Scoreboard";

const defaultParams: ServeChallengeParameters = {
    servePosition: "deuce",
    serveCount: 10,
    targetAOIs: [],
    serveAOI: {
        upperLeftX: 0,
        upperLeftY: 0,
        lowerRightX: 0,
        lowerRightY: 0,
    },
};

const defaultConfig: ServeChallengeConfig = {};

const defaultWorkout: Omit<AppWorkoutCreatePayload, "physicsModelName"> = {
    appId: 12,
    appName: "ServeChallenge",
    description:
        "Practice your serve and volley against challenging realistic returns.",
    name: "Serve and Volley",
    sport: { name: "PLATFORM_TENNIS" },
    contentProviderId: null,
    copiedFromAppWorkoutId: null,
    extendedData: null,
    overview: "",
    state: "BUILD",

    config: defaultConfig as unknown as JSONObject,
    positionHeight: 0,
    positionX: 0,
    positionY: 0,
    positionYaw: 0,
};

type ParamsAction =
    | { type: "servePosition"; value: ServePosition }
    | { type: "serveCount"; value: number };

function paramsReducer(
    params: ServeChallengeParameters,
    action: ParamsAction,
): ServeChallengeParameters {
    switch (action.type) {
        case "servePosition":
            return { ...params, servePosition: action.value };
        case "serveCount":
            return { ...params, serveCount: action.value };
        default:
            return params;
    }
}

type KnownBallCount = "5" | "10" | "15" | "20";
const KnownBallCounts: Record<KnownBallCount, number> = {
    5: 5,
    10: 10,
    15: 15,
    20: 20,
};

const ballCountMarks = Object.keys(KnownBallCounts).map((k) => ({
    label: k,
    value: KnownBallCounts[k as KnownBallCount],
}));

interface ServeChallengeProps {
    appConfig: ServeChallengeAppConfig;
}

function ServeChallenge({ appConfig }: ServeChallengeProps) {
    const [paramsState, paramsDispatch] = React.useReducer(
        paramsReducer,
        defaultParams,
    );
    const [workout, setWorkout] = React.useState<AppWorkout | null>(null);
    const [playClicked, setPlayClicked] = React.useState<boolean>(false);
    const [localizingDialogOpen, setLocalizingDialogOpen] =
        React.useState(false);
    const { isAdmin } = useCurrentUser();

    const { physicsModelName } = usePhysicsModelContext();

    const { status } = useStatus();
    const workoutStatus = status?.workouts;

    const { position, isVisionStarting, isVisionFaulted, cancel } =
        usePosition();

    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";

    const { checkForLift, stop: stopLift } = useLift();

    // we use this ref to track if the settings have been changed and we should start a new game
    const modifiedRef = React.useRef(true);

    // we use this ref to track if a stop has been requested
    const stopRequestedRef = React.useRef(false);

    // we use this ref to force localization
    const forceLocalizationRef = React.useRef(true);

    const { addWorkout } = useAppWorkouts();

    const workoutParams = React.useMemo(
        () => ({
            ...paramsState,
            targetAOIs: appConfig.targetAOIs[paramsState.servePosition],
            serveAOI: appConfig.serveAOI[paramsState.servePosition],
            visionConfig: appConfig.visionConfig,
        }),
        [appConfig, paramsState],
    );

    const {
        start,
        playState,
        playInitiated,
        workoutState,
        playDisabled,
        pauseDisabled,
        captureDisabled,
        captureVideo,
        captureStatus,
        stop: stopWorkout,
    } = useAppWorkoutPlay({
        workout,
        parameters: workoutParams as unknown as JSONObject,
    });

    const plannedTrainerPosition = React.useMemo(() => {
        const pos = appConfig.trainerPosition[paramsState.servePosition];
        return {
            x: pos.positionX,
            y: pos.positionY,
            yaw: pos.positionYaw,
            heightIn: pos.positionHeight,
        };
    }, [appConfig.trainerPosition, paramsState.servePosition]);

    const workoutForLocalizingDialog = React.useMemo(
        () => ({
            trainer: plannedTrainerPosition,
            localized: position && {
                ...position,
                heightIn: plannedTrainerPosition.heightIn,
            },
            player: [],
            shots: [],
            AOIs: [], // TODO add AOIs
        }),
        [plannedTrainerPosition, position],
    );

    const saveWorkout = React.useCallback(async () => {
        if (modifiedRef.current) {
            const newWorkout = await addWorkout({
                ...defaultWorkout,
                physicsModelName,
                ...appConfig.trainerPosition[paramsState.servePosition],
            });

            if (newWorkout !== null) {
                setWorkout(newWorkout);
                setPlayClicked(true);
                modifiedRef.current = false;
            }
        } else {
            setPlayClicked(true);
        }
    }, [
        addWorkout,
        appConfig.trainerPosition,
        paramsState.servePosition,
        physicsModelName,
    ]);

    const handlePlayClicked = React.useCallback(() => {
        stopRequestedRef.current = false;
        setLocalizingDialogOpen(true);
    }, []);

    // Start the workout when the play button is clicked
    React.useEffect(() => {
        if (playClicked && workout) {
            setPlayClicked(false);
            checkForLift();
            void start();
        }
    }, [checkForLift, playClicked, start, workout]);

    const handleStopWorkout = React.useCallback(async () => {
        stopRequestedRef.current = true;
        await stopWorkout();
    }, [stopWorkout]);

    return (
        <>
            <Stack
                sx={{
                    backgroundColor: "background.default",
                    marginLeft: "-8px",
                    marginRight: "-8px",
                }}
            >
                <Typography variant="h4">Serve Challenge</Typography>
                <Box
                    component="div"
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                />
                <Box mb={1}>
                    <OptionSelector
                        disabled={playState === "playing"}
                        label="Server Position"
                        labelWrapperSx={{ flex: 1 }}
                        toggleButtonSx={{ flex: 2 }}
                        options={[
                            { value: "ad", label: "Ad" },
                            { value: "deuce", label: "Deuce" },
                        ]}
                        selected={paramsState.servePosition}
                        setOption={(value) => {
                            if (value !== null) {
                                paramsDispatch({
                                    type: "servePosition",
                                    value,
                                });
                                modifiedRef.current = true;
                                // force localization if we change the serve position
                                forceLocalizationRef.current = true;
                            }
                        }}
                    />
                </Box>
                <Box mb={1}>
                    <ThrowCount
                        label="Serves"
                        disabled={false} // TODO
                        selectedThrowCount={paramsState.serveCount}
                        onUserThrowCountChanged={(number) => {
                            paramsDispatch({
                                type: "serveCount",
                                value: number,
                            });
                            modifiedRef.current = true;
                        }}
                        marks={ballCountMarks}
                        min={5}
                        max={20}
                    />
                </Box>

                <Box pt={8} />

                <Box display="flex" justifyContent="center">
                    <Scoreboard serves={workoutState?.results} />
                </Box>

                <Box pt={2} display="flex" justifyContent="center">
                    <Court
                        serves={workoutState?.results || []}
                        targetAOIs={workoutParams.targetAOIs || []}
                    />
                </Box>

                <Box>
                    <pre>{JSON.stringify(workoutState, null, 2)}</pre>
                </Box>
            </Stack>

            <LocalizingDialog
                dialogOpen={localizingDialogOpen}
                onLocalized={(result) => {
                    setLocalizingDialogOpen(false);
                    if (result === "good") {
                        forceLocalizationRef.current = false;
                        void saveWorkout();
                    } else {
                        forceLocalizationRef.current = true;
                    }
                }}
                onCanceled={() => {
                    cancel();
                    setLocalizingDialogOpen(false);
                }}
                plannedPosition={plannedTrainerPosition}
                force={forceLocalizationRef.current}
                workout={workoutForLocalizingDialog}
            />

            <PlayAppBar
                playState={playState}
                playDisabled={playDisabled || visionUnavailable}
                pauseDisabled={pauseDisabled}
                showRecord={isAdmin()}
                recordDisabled={visionUnavailable || captureDisabled}
                recordingStatus={captureStatus}
                playSummary={undefined} // TODO
                onPlayClicked={handlePlayClicked}
                onPauseClicked={handleStopWorkout}
                onRecordClicked={() => captureVideo()}
            />
        </>
    );
}

export default function ServeChallengeRoot() {
    const [appConfig, setAppConfig] =
        React.useState<ServeChallengeAppConfig | null>(null);

    const { loadServeChallengeConfig } = useAppWorkouts();

    React.useEffect(() => {
        loadServeChallengeConfig()
            .then(setAppConfig)
            .catch((e) =>
                logFetchError(e, "Failed to load serve challenge config"),
            );
    }, [loadServeChallengeConfig]);

    if (!appConfig) {
        return <Typography>Loading...</Typography>;
    }

    return <ServeChallenge appConfig={appConfig} />;
}
