import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { FullPosition } from "@volley/shared/vision-models";

import logger from "../../../../../log";
import CloseableDialogTitle from "../../../../common/CloseableDialogTitle";
import ResizableWorkoutVisualizer from "../../../../common/Visualizer/ResizableWorkoutVisualizer";
import { WorkoutForVisualizer } from "../../../../common/Visualizer/types";
import usePosition from "../../../../hooks/usePosition";
import usePrevious from "../../../../hooks/usePrevious";

import ContactSupport from "./ContactSupport";

interface PlannedPosition {
    x: number;
    y: number;
    yaw: number;
    heightIn: number;
}

type LocalizationResult = "fail" | "good" | "improve";

const DISTANCE_DELTA_ALLOWED = 500; // mm
const HEIGHT_DELTA_ALLOWED = 100; // mm

function isCloseEnough(
    plannedPosition: PlannedPosition,
    position: FullPosition,
): boolean {
    // return true; // TODO remove
    const distance = Math.sqrt(
        (position.x - plannedPosition.x) ** 2 +
            (position.y - plannedPosition.y) ** 2,
    );
    const distanceGood = distance <= DISTANCE_DELTA_ALLOWED;

    const plannedHeightAdjusted = plannedPosition.heightIn * 25.4 + 363; // convert inches to mm with offset
    const heightDiff = Math.abs(position.z - plannedHeightAdjusted);
    const heightGood = heightDiff <= HEIGHT_DELTA_ALLOWED;

    logger.info("[serveAndVolley] closeEnough", {
        plannedPosition,
        position,
        distance,
        distanceGood,
        heightDiff,
        heightGood,
    });

    return distanceGood && heightGood;
}

function useLocalization(
    dialogOpen: boolean,
    plannedPosition: PlannedPosition,
    force: boolean,
    onLocalized: (result: LocalizationResult) => void,
) {
    const [hasTriedOneShot, setHasTriedOneShot] = React.useState(false);
    const [hasTriedSmart, setHasTriedSmart] = React.useState(false);

    const { delta, improve, position, updatePosition, localizationStatus } =
        usePosition();

    const previousLocalizationStatus = usePrevious(localizationStatus);

    // Handle localization status changes
    React.useEffect(() => {
        if (
            localizationStatus === "idle" &&
            previousLocalizationStatus === "localizing"
        ) {
            const shouldDoSmart = false;
            if (shouldDoSmart) {
                // If the one-shot localization failed, try smart localization
                updatePosition(plannedPosition, false);
                setHasTriedSmart(true);
            } else {
                // if we don't have a position, we failed
                if (!position) {
                    onLocalized("fail");
                    return;
                }

                // if we're not close enough, we need to improve
                if (!isCloseEnough(plannedPosition, position as FullPosition)) {
                    onLocalized("improve");
                    return;
                }

                // Otherwise, we're good
                onLocalized("good");
            }
        }
    }, [
        delta,
        improve,
        hasTriedOneShot,
        hasTriedSmart,
        localizationStatus,
        onLocalized,
        position,
        plannedPosition,
        previousLocalizationStatus,
        updatePosition,
    ]);

    // Handle localization when the dialog opens
    React.useEffect(() => {
        if (dialogOpen && !hasTriedOneShot) {
            // If there's a position already and we're not forcing, no need to localize
            if (position && !force) {
                onLocalized("good");
                return;
            }

            // Trigger the one-shot localization
            updatePosition(plannedPosition, true);
            setHasTriedOneShot(true);
        }
    }, [
        dialogOpen,
        force,
        hasTriedOneShot,
        onLocalized,
        plannedPosition,
        position,
        updatePosition,
    ]);

    const retry = React.useCallback(() => {
        updatePosition(plannedPosition, true);
        setHasTriedOneShot(true);
    }, [plannedPosition, updatePosition]);

    // Reset the state when the dialog closes
    React.useEffect(() => {
        if (!dialogOpen) {
            setHasTriedOneShot(false);
            setHasTriedSmart(false);
        }
    }, [dialogOpen]);

    return {
        localizationStatus,
        retry,
    };
}

function LocalizationFailedContent(): JSX.Element {
    return (
        <Box component="div">
            <Typography mb={2}>Please confirm the following:</Typography>
            <Typography component="ul" mb={2}>
                <li>
                    The camera is not pointed into harsh lighting conditions
                    (sunrise or sunset)
                </li>
                <li>
                    The trainer is positioned according to the photo under View
                    Instructions
                </li>
                <li>Ad/Deuce appropriately selected</li>
                <li>
                    The camera is not obstructed by the cover or another object
                </li>
                <li>
                    There are no other lines on the platform court (pickleball
                    lines or chalk)
                </li>
            </Typography>
            <ContactSupport />
        </Box>
    );
}

function LocalizationInProgressContent(): JSX.Element {
    return (
        <Stack alignItems="center" direction="column">
            <Typography variant="body1" mb={2}>
                The trainer is confirming it is in the correct place before
                starting.
            </Typography>
            <CircularProgress size={80} color="info" />
        </Stack>
    );
}

interface LocalizationImproveContentProps {
    workout: WorkoutForVisualizer | undefined;
}
function LocalizationImproveContent({
    workout,
}: LocalizationImproveContentProps): JSX.Element {
    const [showHelp, setShowHelp] = React.useState(false);

    return (
        <Box>
            <ResizableWorkoutVisualizer
                workout={workout}
                maxHeight={225}
                positionProximity="Improve"
                improveMode="xy"
            />
            <Box component="div">
                <Typography
                    variant="body2"
                    color="primary.main"
                    display="inline"
                >
                    Trainer Position:
                </Typography>
                <Typography variant="body2" color="info" display="inline">
                    Improve
                </Typography>
                <IconButton
                    size="small"
                    edge="end"
                    color="info"
                    aria-label="help"
                    onClick={() => {
                        setShowHelp(true);
                    }}
                >
                    <HelpIcon />
                </IconButton>
            </Box>
            <Dialog open={showHelp} style={{ whiteSpace: "pre-wrap" }}>
                <DialogTitle>Improve Trainer Position</DialogTitle>
                <DialogContent>
                    Please move the trainer closer to the transparent trainer by
                    following the arrow. Click the green button to retry.
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => setShowHelp(false)}
                    >
                        Got it!
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

interface Props {
    dialogOpen: boolean;
    onLocalized: (result: LocalizationResult) => void;
    onCanceled: () => void;
    plannedPosition: PlannedPosition;
    force: boolean;
    workout?: WorkoutForVisualizer;
}

export default function LocalizingDialog({
    dialogOpen,
    onLocalized,
    onCanceled,
    plannedPosition,
    force,
    workout,
}: Props) {
    const [result, setResult] = React.useState<
        LocalizationResult | undefined
    >();
    const { localizationStatus, retry } = useLocalization(
        dialogOpen,
        plannedPosition,
        force,
        (r) => {
            setResult(r);
        },
    );

    React.useEffect(() => {
        if (!dialogOpen) {
            setResult(undefined);
        }
    }, [dialogOpen]);

    React.useEffect(() => {
        if (result === "good") {
            logger.info("[serveAndVolley] localized result", { result });
            onLocalized(result);
        }
    }, [onLocalized, result]);

    const onRetry = React.useCallback(() => {
        setResult(undefined);
        retry();
    }, [retry]);

    const title = React.useMemo(() => {
        switch (result) {
            case "good":
                return "Position Confirmed";
            case "fail":
                return "Failed to Confirm Position";
            case "improve":
                return "Position Needs Improvement";
            default:
                return "Confirming Position";
        }
    }, [result]);

    return (
        <Dialog open={dialogOpen} fullWidth>
            <CloseableDialogTitle>
                <Typography variant="h6">{title}</Typography>
            </CloseableDialogTitle>
            <DialogContent>
                {localizationStatus === "localizing" && (
                    <LocalizationInProgressContent />
                )}

                {result === "improve" && (
                    <LocalizationImproveContent workout={workout} />
                )}
                {result === "fail" && <LocalizationFailedContent />}
            </DialogContent>
            <DialogActions>
                <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                    {localizationStatus === "localizing" && (
                        <Button
                            variant="contained"
                            color="error"
                            onClick={onCanceled}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    )}

                    {result && (
                        <Button
                            variant="contained"
                            color="info"
                            onClick={() => onLocalized(result)}
                            fullWidth
                        >
                            Dismiss
                        </Button>
                    )}

                    {(result === "fail" || result === "improve") && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={onRetry}
                            fullWidth
                        >
                            Retry
                        </Button>
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

LocalizingDialog.defaultProps = {
    workout: undefined,
};
