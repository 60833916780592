import * as React from "react";

import Box from "@mui/material/Box";

import { Serve } from "./models";

interface ScoreboardProps {
    serves?: Serve[];
}

export default function Scoreboard({
    serves,
}: ScoreboardProps): JSX.Element | null {
    const total = React.useMemo(() => serves?.length, [serves]);

    const inAoi = React.useMemo(
        () => serves?.filter((serve) => serve.inAoi).length,
        [serves],
    );

    if (!serves) {
        return null;
    }

    return (
        <Box>
            {inAoi}
            {" / "}
            {total}
        </Box>
    );
}

Scoreboard.defaultProps = {
    serves: undefined,
};
